import axios from "axios";
import React, { useState, useEffect } from "react";
import { Breadcrumb, Col, Container, Row, Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import { dateToTitleFormat } from "../utils/DateUtils";
import { configuration } from "../config/config";
import Loading from "./Loading";
import NewsRoomBanner from "../asset/banner/newsroom-banner.png";
import "../stylesheets/events.css";

const getFeaturesCollection = () => {
  return axios.get("/feature-collections").then((res) => {
    return res?.data;
  });
};

const getFeatureOfCollection = (slug) => {
  return axios.get(`/features?feature_collection.slug=${slug}`).then((res) => {
    return res?.data;
  });
};

const getFeature = (id) => {
  return axios.get(`/features?id=${id}`).then((res) => {
    return res?.data?.[0];
  });
};

const getLeftAds = () => {
  return axios.get("/article-page-ads-left").then((res) => {
    return res?.data;
  });
};

const getRigthAds = () => {
  return axios.get("/article-page-ads-right").then((res) => {
    return res?.data;
  });
};

const navigateToLink = (url) => {
  window.open(
    url,
    "_blank" // <- This is what makes it open in a new window.
  );
};

export default function Features(props) {
  const [collections, setCollections] = useState([]);
  const [features, setFeatures] = useState([]);
  const [feature, setFeature] = useState(null);
  const [leftAds, setLeftAds] = useState(null);
  const [rightAds, setRightAds] = useState(null);
  const [loading, setLoading] = useState(false);

  const slug = props?.match?.params?.slug;
  const id = props?.match?.params?.id;

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    getFeaturesCollection().then((res) => {
      setCollections(res);
      setLoading(false);
    });

    getLeftAds().then((res) => setLeftAds(res));

    getRigthAds().then((res) => setRightAds(res));
  }, []);

  useEffect(() => {
    if (slug) {
      setLoading(true);
      getFeatureOfCollection(slug).then((res) => {
        setFeatures(res);
        setLoading(false);
      });
    }
  }, [slug]);

  useEffect(() => {
    if (id) {
      window.scrollTo(0, 0);
      setLoading(true);
      getFeature(id).then((res) => {
        setFeature({
          title: res?.title,
          description: res?.tileDescription,
          image:
            res?.tileImage && res?.tileImage.formats.large
              ? configuration.strapiURL + res?.tileImage.formats.large.url
              : configuration.strapiURL + res?.tileImage.formats.small.url,
          para1: res?.ParagraphFirst,
          para2: res?.ParagraphSecond,
          para3: res?.ParagraphThird,
          author: res?.author,
          date: dateToTitleFormat(res?.date, "MMM DD, yyyy"),
          disclamier: res?.disclamier,
          imageCaption: res?.imageCaption
        });
        setLoading(false);
      });
    }
  }, [id]);

  const readMore = (item) => {
    props?.history?.push({
      pathname: `/features/${item?.slug}`,
      state: { item }
    });
  };

  const readMoreFeature = (item) => {
    props?.history?.push({
      pathname: `/features/${item?.feature_collection?.slug}/${item?.id}`,
      state: { item }
    });
  };

  if (loading) {
    return <Loading />;
  }

  // Feature
  if (slug && id)
    return (
      <Container fluid className="Events-container">
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/">Home</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="/features">Features</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink
                  to={`/features/${features?.[0]?.feature_collection?.slug}`}
                >
                  {features?.[0]?.feature_collection?.title}
                </NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">{feature?.title}</NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Container>
        <Container className="member-container">
          <h1>{feature?.title}</h1>
          <div className="overview">
            <ReactMarkdown>{feature?.description}</ReactMarkdown>
          </div>
          <div className="article-details">
            <div className="article-author">{feature?.author}</div> |
            <div className="article-date"> Posted on {feature?.date}</div>
          </div>
          <div className="article-img">
            <img src={feature?.image} alt="Main"></img>
            <div className="article-title-image-desc">
              {feature?.imageCaption ? (
                <blockquote>
                  <ReactMarkdown source={feature?.imageCaption}></ReactMarkdown>
                </blockquote>
              ) : null}
            </div>
          </div>
          <Row>
            <Col md={9} className="article-section">
              <br />
              <ReactMarkdown>
                {feature?.para1
                  ? feature?.para1.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              <br />
              <div>
                {leftAds &&
                  leftAds.advertisementOne.formats &&
                  leftAds.advertisementOne.formats.large && (
                    <img
                      onClick={(e) =>
                        navigateToLink(leftAds.advertisementOneUrl)
                      }
                      src={
                        configuration.strapiURL +
                        leftAds.advertisementOne.formats.large.url
                      }
                      width="100%"
                      alt="Article Ad"
                    ></img>
                  )}
              </div>
              <br />
              <ReactMarkdown>
                {feature?.para2
                  ? feature?.para2.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              <br />
              <div className="article-img">
                {leftAds &&
                leftAds.advertisementTwo &&
                leftAds.advertisementTwo.formats &&
                leftAds.advertisementTwo.formats.large ? (
                  <img
                    src={
                      configuration.strapiURL +
                      leftAds.advertisementTwo.formats.large.url
                    }
                    width="100%"
                    alt="Article Ad"
                  ></img>
                ) : leftAds &&
                  leftAds.advertisementTwo &&
                  leftAds.advertisementTwo.formats &&
                  leftAds.advertisementTwo.formats.medium ? (
                  <img
                    src={
                      configuration.strapiURL +
                      leftAds.advertisementTwo.formats.medium.url
                    }
                    width="100%"
                    alt="Article Ad"
                  ></img>
                ) : leftAds &&
                  leftAds.advertisementTwo &&
                  leftAds.advertisementTwo.formats &&
                  leftAds.advertisementTwo.formats.thumbnail ? (
                  <img
                    src={
                      configuration.strapiURL +
                      leftAds.advertisementTwo.formats.thumbnail.url
                    }
                    width="100%"
                    alt="Article Ad"
                  ></img>
                ) : null}
              </div>
              <br />
              <ReactMarkdown>
                {feature?.para3
                  ? feature?.para3.replaceAll(
                      "](/",
                      "](https://admin.asme.org.sg/"
                    )
                  : null}
              </ReactMarkdown>
              <div className="disclamier">
                <em>{feature?.disclamier}</em>
              </div>
            </Col>
            <Col md={3} className="article-right-ad">
              <Row>
                <Col md={12}>
                <div className="article-ad">ADVERTISEMENT</div>
                  {rightAds &&
                    rightAds.advertisementOne.formats &&
                    rightAds.advertisementOne.formats.large && (
                      <img
                        onClick={(e) =>
                          navigateToLink(rightAds.advertisementOneUrl)
                        }
                        src={
                          configuration.strapiURL +
                          rightAds.advertisementOne.formats.large.url
                        }
                        width="100%"
                        alt="Article Ad"
                      ></img>
                    )}
                </Col>
                <Col md={12}>
                  {rightAds &&
                  rightAds.advertisementTwo.formats &&
                  rightAds.advertisementTwo.formats.large ? (
                    <img
                      onClick={(e) =>
                        navigateToLink(rightAds.advertisementTwoUrl)
                      }
                      src={
                        configuration.strapiURL +
                        rightAds.advertisementTwo.formats.large.url
                      }
                      width="100%"
                      alt="Article Ad"
                    ></img>
                  ) : rightAds &&
                    rightAds.advertisementTwo.formats &&
                    rightAds.advertisementTwo.formats.medium ? (
                    <img
                      onClick={(e) =>
                        navigateToLink(rightAds.advertisementTwoUrl)
                      }
                      src={
                        configuration.strapiURL +
                        rightAds.advertisementTwo.formats.medium.url
                      }
                      width="100%"
                      alt="Article Ad"
                    ></img>
                  ) : null}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
    );

  // Features in a Collection
  if (slug && !id)
    return (
      <Container fluid className="Events-container">
        <Container fluid className="banner-container">
          <img
            src={NewsRoomBanner}
            alt="Features Banner"
            width="100%"
            className="banner-img"
          ></img>
          <h1>Features</h1>
        </Container>
        <Container>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <NavLink to="/">Home</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="/features">Features</NavLink>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <NavLink to="#">
                  {features?.[0]?.feature_collection?.title}
                </NavLink>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
          <Row>
            <h2 className="news-room-header">
              {features?.[0]?.feature_collection?.title}
            </h2>
          </Row>
        </Container>
        <Container className="member-container">
          <Row>
            {features.map((featureCollection) => {
              return (
                <Col
                  md={4}
                  className="event-container cursor-pointer"
                  onClick={(e) => readMoreFeature(featureCollection)}
                  key={`featureCollection-${featureCollection?.id}`}
                >
                  <Card className="event-card">
                    {featureCollection &&
                    featureCollection?.tileImage &&
                    featureCollection?.tileImage?.formats &&
                    featureCollection?.tileImage?.formats.large ? (
                      <Card.Img
                        variant="top"
                        src={
                          configuration.strapiURL +
                          featureCollection?.tileImage?.formats.large.url
                        }
                      />
                    ) : featureCollection &&
                      featureCollection?.tileImage &&
                      featureCollection?.tileImage?.formats &&
                      featureCollection?.tileImage?.formats.medium ? (
                      <Card.Img
                        variant="top"
                        src={
                          configuration.strapiURL +
                          featureCollection?.tileImage?.formats.medium.url
                        }
                      />
                    ) : featureCollection &&
                      featureCollection?.tileImage &&
                      featureCollection?.tileImage?.formats &&
                      featureCollection?.tileImage?.formats.small ? (
                      <Card.Img
                        variant="top"
                        src={
                          configuration.strapiURL +
                          featureCollection?.tileImage?.formats.small.url
                        }
                      />
                    ) : null}
                    <div className="newsroom-date">
                      {dateToTitleFormat(
                        featureCollection?.date,
                        "MMM DD, yyyy"
                      )}
                    </div>
                    <Card.Body>
                      <Card.Text>
                        <div className="event-title">
                          {featureCollection?.title}
                        </div>
                      </Card.Text>

                      <div className="event-title">
                        {featureCollection?.tileDescription}
                      </div>

                      <div className="psa-readmore">Read More</div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
            {features?.length === 0 && (
              <div className="pl-3">No features found</div>
            )}
          </Row>
        </Container>
      </Container>
    );

  // Feature Collections (!slug && !id)
  return (
    <Container fluid className="Events-container">
      <Container fluid className="banner-container">
        <img
          src={NewsRoomBanner}
          alt="Features Banner"
          width="100%"
          className="banner-img"
        ></img>
        <h1>Features</h1>
      </Container>
      <Container>
        <Row>
          <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Home</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <NavLink to="/features">Features</NavLink>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row>
          <h2 className="news-room-header">Features</h2>
        </Row>
      </Container>
      <Container className="member-container">
        <Row>
          {collections.map((collection) => {
            return (
              <Col
                md={4}
                className="event-container cursor-pointer"
                onClick={() => readMore(collection)}
                key={`collection-${collection?.id}`}
              >
                <Card className="event-card">
                  {collection &&
                  collection?.thumbnail &&
                  collection?.thumbnail?.formats &&
                  collection?.thumbnail?.formats.large ? (
                    <Card.Img
                      variant="top"
                      src={
                        configuration.strapiURL +
                        collection?.thumbnail?.formats.large.url
                      }
                    />
                  ) : collection &&
                    collection?.thumbnail &&
                    collection?.thumbnail?.formats &&
                    collection?.thumbnail?.formats.medium ? (
                    <Card.Img
                      variant="top"
                      src={
                        configuration.strapiURL +
                        collection?.thumbnail?.formats.medium.url
                      }
                    />
                  ) : collection &&
                    collection?.thumbnail &&
                    collection?.thumbnail?.formats &&
                    collection?.thumbnail?.formats.small ? (
                    <Card.Img
                      variant="top"
                      src={
                        configuration.strapiURL +
                        collection?.thumbnail?.formats.small.url
                      }
                    />
                  ) : null}
                  <Card.Body>
                    <Card.Text>
                      <div className="event-title">{collection?.title}</div>
                    </Card.Text>

                    <div className="event-title">{collection?.description}</div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
          {collections?.length === 0 && (
            <div className="pl-3">No features found</div>
          )}
        </Row>
      </Container>
    </Container>
  );
}
